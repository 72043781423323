<template>
  <div style="width: 100%" v-loading="loading" class="student-detail">
    <!-- 学员信息 -->
    <div class="student-view admin-view-container">
      <div class="admin-table-search">
        <div class="step">
          <div
            class="num"
            :class="this.tableData.examSub1Status == 3 ? 'fail' : this.tableData.examSub1Status == 4 ? 'success' : ''">
            <span v-if="this.tableData.examSub1Status == 1 || this.tableData.examSub1Status == 2">1</span>
            <span v-if="this.tableData.examSub1Status == 3"><i class="el-icon-close"></i></span>
            <span v-if="this.tableData.examSub1Status == 4"><i class="el-icon-check"></i></span>
          </div>
          <div class="info">
            <div class="info-header">科目一</div>
            <div class="info-bottom">
              {{ $dict.getData($dict.examSubStatus, this.tableData.examSub1Status).label }}
            </div>
          </div>
        </div>
        <div class="step">
          <div
            class="num"
            :class="this.tableData.examSub2Status == 3 ? 'fail' : this.tableData.examSub2Status == 4 ? 'success' : ''">
            <span v-if="this.tableData.examSub2Status == 1 || this.tableData.examSub2Status == 2">2</span>
            <span v-if="this.tableData.examSub2Status == 3"><i class="el-icon-close"></i></span>
            <span v-if="this.tableData.examSub2Status == 4"><i class="el-icon-check"></i></span>
          </div>
          <div class="info">
            <div class="info-header">科目二</div>
            <div class="info-bottom">
              {{ $dict.getData($dict.examSubStatus, this.tableData.examSub2Status).label }}
            </div>
          </div>
        </div>
        <div class="step">
          <div
            class="num"
            :class="this.tableData.examSub3Status == 3 ? 'fail' : this.tableData.examSub3Status == 4 ? 'success' : ''">
            <span v-if="this.tableData.examSub3Status == 1 || this.tableData.examSub3Status == 2">3</span>
            <span v-if="this.tableData.examSub3Status == 3"><i class="el-icon-close"></i></span>
            <span v-if="this.tableData.examSub3Status == 4"><i class="el-icon-check"></i></span>
          </div>
          <div class="info">
            <div class="info-header">科目三</div>
            <div class="info-bottom">
              {{ $dict.getData($dict.examSubStatus, this.tableData.examSub3Status).label }}
            </div>
          </div>
        </div>
        <div class="step">
          <div
            class="num"
            :class="this.tableData.examSub4Status == 3 ? 'fail' : this.tableData.examSub4Status == 4 ? 'success' : ''">
            <span v-if="this.tableData.examSub4Status == 1 || this.tableData.examSub4Status == 2">4</span>
            <span v-if="this.tableData.examSub4Status == 3"><i class="el-icon-close"></i></span>
            <span v-if="this.tableData.examSub4Status == 4"><i class="el-icon-check"></i></span>
          </div>
          <div class="info">
            <div class="info-header">科目四</div>
            <div class="info-bottom">
              {{ $dict.getData($dict.examSubStatus, this.tableData.examSub4Status).label }}
            </div>
          </div>
        </div>
      </div>

      <div class="admin-table-list">
        <el-descriptions title="学员信息" direction="vertical" :column="5" border>
          <el-descriptions-item label="姓名">{{ tableData.drvStudentName }}</el-descriptions-item>
          <el-descriptions-item label="报考驾校">{{ tableData.drvSchoolForShort }}</el-descriptions-item>
          <el-descriptions-item label="报名时间">{{ $utils.formatDateFull(tableData.enrollTime) }}</el-descriptions-item>
          <el-descriptions-item label="身份证号">{{ tableData.drvStudentIdentityCard }}</el-descriptions-item>
          <el-descriptions-item label="业务类型">{{ tableData.vehicleType }}</el-descriptions-item>
        </el-descriptions>
      </div>
    </div>

    <!-- 科目信息 -->
    <div class="exam-sub-view">
      <div class="admin-view-container">
        <p class="sub-refresh-view">
          <span>
            科目信息
            <el-tooltip class="item" effect="light" content="只展示当前最新数据" placement="right">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </span>
          <el-button :disabled="refreshFlag" type="primary" @click="subRefreshHandler">数据更新</el-button>
        </p>
        <div class="admin-table-list">
          <el-table :data="subList" border>
            <el-table-column prop="examSub" label="科目类型" :formatter="$formatter.dict($dict.examSub)"></el-table-column>
            <el-table-column prop="drvExamVenueName" label="考试地点"></el-table-column>
            <el-table-column prop="examSubTime" label="考试时间" :formatter="$formatter.date(true)"></el-table-column>
            <el-table-column prop="passStatus" label="考试结果">
              <template slot-scope="scope">
                <span :style="scope.row.passStatus == 2 ? 'color:red' : scope.row.passStatus == 1 ? 'color:green' : ''">
                  {{ $dict.getData($dict.passStatus, scope.row.passStatus).label }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <!-- 考试信息 -->
    <div class="exam-view">
      <div class="admin-view-container">
        <p>考试信息</p>
        <div class="admin-table-list">
          <el-table
            :data="resultList"
            border
            :row-class-name="getRowClass"
            ref="expandTable"
            @row-click="toggleRowExpansion">
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-table :data="props.row.children" align="center" :span-method="arraySpanMethod">
                  <el-table-column align="center">
                    <template slot-scope="scope1">
                      {{ scope1.row.title }}
                    </template>
                  </el-table-column>
                  <el-table-column align="center" prop="startTime" label="考试时间">
                    <template slot-scope="scope">
                      {{ $utils.formatDuration(scope.row.startTime) }}
                    </template>
                  </el-table-column>
                  <el-table-column align="center" prop="passStatus" label="考试结果">
                    <template slot-scope="scope">
                      <span :style="scope.row.passStatus == 1 ? 'color: green' : 'color: red'">
                        {{
                          scope.row.passStatus
                            ? $dict.getData($dict.passStatus, scope.row.passStatus).label
                            : scope.row.compensateStatus == 1
                            ? '异常'
                            : $dict.getData($dict.compensateStatus, scope.row.compensateStatus).label
                        }}
                        <el-tooltip
                          class="item"
                          effect="light"
                          content="缺考或已取消"
                          placement="right"
                          v-if="!scope.row.passStatus && scope.row.compensateStatus">
                          <i class="el-icon-question"></i>
                        </el-tooltip>
                      </span>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column prop="drvExamVenueName" label="考试地点" width="410"></el-table-column>
            <el-table-column prop="startTime" label="考试日期" :formatter="$formatter.date()" width="110"></el-table-column>
            <el-table-column prop="usableVehicleType" label="业务类型" width="110"></el-table-column>
            <el-table-column
              prop="examSub"
              label="科目类型"
              :formatter="$formatter.dict($dict.examSub)"
              width="110"></el-table-column>
            <el-table-column prop="examCounts" label="考试次数" width="110">
              <template slot-scope="scope">
                <el-link type="primary" @click="toggleRowExpansion">
                  <u>{{ scope.row.examCounts }}</u>
                </el-link>
              </template>
            </el-table-column>
            <el-table-column prop="passStatus" label="考试结果">
              <template slot-scope="scope">
                <span :style="scope.row.passStatus == 1 ? 'color: green' : 'color: red'">
                  {{
                    scope.row.passStatus
                      ? $dict.getData($dict.passStatus, scope.row.passStatus).label
                      : scope.row.compensateStatus == 1
                      ? '异常'
                      : $dict.getData($dict.compensateStatus, scope.row.compensateStatus).label
                  }}
                  <el-tooltip
                    class="item"
                    effect="light"
                    content="缺考或已取消"
                    placement="right"
                    v-if="!scope.row.passStatus && scope.row.compensateStatus">
                    <i class="el-icon-question"></i>
                  </el-tooltip>
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <div class="admin-dialog-btns">
      <el-button
        @click="
          () => {
            this.$emit('end');
          }
        "
        >取消
      </el-button>
    </div>
  </div>
</template>

<script>
import {drvStudentGetStudent, drvStudentRefreshStudentExamResult} from '@/api/index';
export default {
  props: {
    row: Object,
  },
  data() {
    return {
      loading: false,
      refreshFlag: false,
      tableData: {
        drvStudentName: '', // 学员名称
        drvStudentIdentityCard: '', // 学员身份证号
        drvSchoolForShort: '', // 驾校简称
        usableVehicleType: '', // 车型
        examList: [],
        examSubList: [],
      },
      subList: [
        {
          examSub: '1', // 科目
          examSubStatus: '--', // 科目状态 1未约考 2已约考 3未通过 4已通过
          drvExamVenueName: '--', // 考场名称
          examSubTime: null, // 考试时间
          passStatus: '--', // 合格标记   1合格 2不合格
        },
        {
          examSub: '2', // 科目
          examSubStatus: '--', // 科目状态 1未约考 2已约考 3未通过 4已通过
          drvExamVenueName: '--', // 考场名称
          examSubTime: null, // 考试时间
          passStatus: '--', // 合格标记   1合格 2不合格
        },
        {
          examSub: '3', // 科目
          examSubStatus: '--', // 科目状态 1未约考 2已约考 3未通过 4已通过
          drvExamVenueName: '--', // 考场名称
          examSubTime: null, // 考试时间
          passStatus: '--', // 合格标记   1合格 2不合格
        },
        {
          examSub: '4', // 科目
          examSubStatus: '--', // 科目状态 1未约考 2已约考 3未通过 4已通过
          drvExamVenueName: '--', // 考场名称
          examSubTime: null, // 考试时间
          passStatus: '--', // 合格标记   1合格 2不合格
        },
      ],
      resultList: [],
      resultAllList: {
        // 1519618951:[]
      },
    };
  },
  created() {
    this.getData();
    this.subRefreshHandler();
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods: {
    subRefreshHandler() {
      this.loading = true;
      this.refreshFlag = true;
      drvStudentRefreshStudentExamResult({businessSn: this.$props.row.businessSn}).then((res) => {
        this.loading = false;
        this.timer = setTimeout(() => {
          this.refreshFlag = false;
        }, 5000);
        if (res.data.code == 0) {
          this.resultList = [];
          this.getData();
        } else {
          this.$message.error(res.data.msg);
          return false;
        }
      });
    },

    // 展开事件
    toggleRowExpansion(row) {
      row.expanded = !row.expanded;
      this.$refs.expandTable.toggleRowExpansion(row, row.expanded);
    },

    getRowClass(row) {
      if (!row.row.children) {
        return 'row-expand-cover';
      }
    },

    // 合并列筛选
    flitterData(arr) {
      let spanOneArr = [];
      let concatOne = 0;
      arr.forEach((item, index) => {
        if (index === 0) {
          spanOneArr.push(1);
        } else {
          if (item && arr[index - 1] && item.a === arr[index - 1].a) {
            // 第一列需合并相同内容的判断条件
            spanOneArr[concatOne] += 1;
            spanOneArr.push(0);
          } else {
            spanOneArr.push(1);
            concatOne = index;
          }
        }
      });
      return {
        one: spanOneArr,
      };
    },
    // 合并相同列
    arraySpanMethod({rowIndex, columnIndex}) {
      if (columnIndex === 0) {
        let data = [];
        data = this.resultList;
        const _row = this.flitterData(data).one[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },

    getData() {
      this.loading = true;
      drvStudentGetStudent({businessSn: this.$props.row.businessSn}).then((res) => {
        this.loading = false;
        if (res.data.code == 0) {
          res.data.data.examSubList.forEach((item) => {
            if (item.examSub == 1) {
              this.subList[0] = item;
            } else if (item.examSub == 2) {
              this.subList[1] = item;
            } else if (item.examSub == 3) {
              this.subList[2] = item;
            } else if (item.examSub == 4) {
              this.subList[3] = item;
            }
          });
          res.data.data.examResultList.forEach((item) => {
            item['dateText'] = this.$utils.formatDate(item.startTime);
            this.resultAllList[`${item.dateText + item.examSub}`] = [];
          });
          res.data.data.examResultList.forEach((item) => {
            this.resultAllList[`${item.dateText + item.examSub}`].push(item);
          });
          let arr = Object.values(this.resultAllList);
          arr.forEach((item, index) => {
            if (item.length) {
              let Object = {};
              Object.id = index;
              Object.drvExamVenueName = item[0].drvExamVenueName;
              Object.startTime = item[0].startTime;
              Object.usableVehicleType = item[0].usableVehicleType;
              Object.examSub = item[0].examSub;
              Object.compensateStatus = item[0].compensateStatus;
              Object.passStatus = 0;
              Object.examCounts = item.length;
              let passArr = [];
              item.forEach((product, index1) => {
                product['id'] = 100 + index1;
                product['title'] = '考试详情';
                if (product.passStatus) {
                  passArr.push(product.passStatus);
                }
              });
              if (passArr.length) {
                if (passArr.indexOf(1) > -1) {
                  Object.passStatus = 1;
                } else if (passArr.indexOf(2) > -1) {
                  Object.passStatus = 2;
                } else {
                  Object.passStatus = 0;
                }
              }
              Object.children = JSON.parse(JSON.stringify(item));
              this.resultList.push(Object);
            }
            // else if (item.length == 1) {
            //     item[0].id = index;
            //     item[0].examCounts = 1;
            //     this.resultList.push(item[0]);
            //   }
          });
          this.tableData = res.data.data;
        } else {
          this.$message.error(res.data.msg);
          this.$emit('end');
        }
      });
    },
  },
};
</script>

<style lang="scss">
.student-detail {
  .sub-refresh-view {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 15px;
  }
  .admin-table-list {
    .el-table .row-expand-cover .cell .el-table__expand-icon {
      display: none;
    }
  }
  .admin-table-search {
    display: flex;
    justify-content: space-evenly;
    .step {
      display: flex;
      align-items: center;
      .num {
        width: 20px;
        height: 20px;
        border-radius: 20px;
        border: solid 1px $--color-primary;
        text-align: center;
        line-height: 20px;
        margin-right: 5px;
      }
      .success {
        width: 24px;
        height: 24px;
        line-height: 24px;
        border: 0;
        background: $--color-success;
        color: white;
      }
      .fail {
        width: 24px;
        height: 24px;
        line-height: 24px;
        border: 0;
        background: $--color-danger;
        color: white;
      }
      .info {
        text-align: center;
        .info-header {
          font-size: 16px;
          height: 24px;
        }
        .info-bottom {
          font-size: 12px;
        }
      }
    }
  }
  .exam-sub-view {
    margin: 20px 0;
    .admin-view-container {
      p {
        font-size: 16px;
        font-weight: bold;
        color: #333;
        padding-left: 20px;
      }
    }
  }
  .exam-view {
    .admin-view-container {
      p {
        font-size: 16px;
        font-weight: bold;
        color: #333;
        padding-left: 20px;
      }
    }
  }
  .admin-dialog-btns {
    margin-top: 20px;
  }
}
</style>
